#auth-container {
    transition: opacity 1s ease;
}
/* .modal-container {
    background-color: pink;
    min-height: 20px;
    width: var(--width);
    padding: 60px 0 20px;
    position: relative;
} */

#auth-container h1 {
    padding: 0 20px 10px;
}
#auth-container form {
    width: var(--width);
    padding-top: 10px;
}

#auth-container form input,
#auth-container form button  {
    font-size: 18px;
    display: block;
    margin: 10px auto;
    border-radius: 3px;
}
#auth-container form input  {
    height: 32px;
    width: 260px;
    font-size: 18px;
    border: 1px solid #DDD;
    padding: 0 10px;
}
#auth-container form button  {
    height: 36px;
    width: 282px;
    font-size: 18px;
    color: #fff;
    background: #000;
    border: 4px solid #000;
    padding: 0;
}
#auth-container form p {
    width: 100%;
    text-align: center;
    display: inline-block;
}

#auth-container section {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}
#auth-container .link-btn {
    display: inline-block;
    background: none;
    border: none;
    padding: 0 6px;
    text-decoration: underline;
    color: blue;
}
#auth-container .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}