#nav-menu {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 8;
    transition: all 1s ease;
    background-color: #fff;
    height: 100%;
}



#nav-menu a,
 #nav-menu button {
    display: block;
    padding: 0;
    line-height: 100%;
    font-size: 18px;
    margin-bottom: 20px;
    border: none;
    background: transparent;
    text-decoration: none;
}

#nav-menu a {
    display: block;
    min-width: 320px;
    /* font-size: 18px; */
    margin-bottom: 20px;
}
#nav-menu a.active{
    text-decoration: underline;
}
#nav-menu > div {
    width: 100%;
    padding: 120px 60px;
}

/* Menu for Draw Page */
#nav-menu {
    padding-right: 24px;
    overflow: hidden;
  
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 1s ease;
    background: #fff;
    box-shadow: -4px 0px 50px rgba(0 0 0 / 12.5%);
  }
  
  #nav-menu.hide-menu {
    transform: translate(100%, -0%);
    opacity: 0;
    transition: transform .3 ease;
  }

.toggle-reverse-btn {
    opacity: .5;
  }
  @media (max-width: 749px) {  
    #nav-menu {
        background: #fff;
        height: 100dvh;
        width: 100%;
        max-width: 100vw;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        /* position: absolute; */
        top: 0;
    }
    #nav-menu a {
      margin-left: 16%; 
    }
  }