.spread-section { 
    display: grid; 
    max-width: 1200px;
    margin: 0 auto;
    gap: 10px;
}


.spread-section > div {
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 25%);
    overflow: hidden;
    transition: box-shadow 2s ease;
}
.spread-section > div:hover {
    cursor: pointer;
    box-shadow: 5px 5px 8px rgb(0 0 0 / 50%);
}
.spread-section > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Flip card orientation with toggle button */
.spread-section.toggle-orientation > div > img {
    transform: rotate(0deg)!important;
}



/*-----------------------------------------------------> CARD PLACEMENT GRID AREA */
.spread-section > div:first-of-type { grid-area: one; }
.spread-section > div:nth-of-type(2) { grid-area: two; }
.spread-section > div:nth-of-type(3) { grid-area: three; }
.spread-section > div:nth-of-type(4) { grid-area: four; }
.spread-section > div:nth-of-type(5) { grid-area: five; }
.spread-section > div:nth-of-type(6) { grid-area: six; }
.spread-section > div:nth-of-type(7) { grid-area: seven; }
.spread-section > div:nth-of-type(8) { grid-area: eight; }
.spread-section > div:nth-of-type(9) { grid-area: nine; }
.spread-section > div:nth-of-type(10) { grid-area: ten; }
.spread-section > div:nth-of-type(11) { grid-area: eleven; }
.spread-section > div:nth-of-type(12) { grid-area: twelve; }
.spread-section > div:nth-of-type(13) { grid-area: thirteen; }

.spread-three-simple,
.spread-five-simple,
.spread-horseshoe {
    margin-top: 40px;
}

/*-----------------------------------------------------> SINGLE CARD */
    .random-single-card > section,
    .draw-single-card > section {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 100%;
        grid-template-areas: ' . one . ';
    }

/*-----------------------------------------------------> SIMPLE SPREADS */
    .spread-three-simple > section {
        max-width: 600px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 100%;
        grid-template-areas: ' one two three ';
    }
    .spread-five-simple > section {
        max-width: 600px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 100%;
        grid-template-areas: ' one two three four five ';
    }

/*-----------------------------------------------------> HORSESHOE SPREAD */
    .spread-seven-simple > section,
    .spread-horseshoe > section {
        max-width: 800px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr .5fr;
        grid-template-areas: 
            ' one . . . . . seven '
            ' . two three four five six . '
            ' . . . . . . . ';
    }
    .spread-horseshoe > section img { max-width: 100%!important; }
        
    .spread-horseshoe > section > div:nth-of-type(2),
    .spread-horseshoe > section > div:nth-of-type(6) {
        transform: translateY(-50%);
    }
    .spread-horseshoe > section > div:nth-of-type(4) {
        transform: translateY(50%);
    }

/*-----------------------------------------------------> CROSS SPREAD */
    .spread-cross > section {
        /* max-width: 450px; */
        /* width: 33vw; */
        /* width: calc(62dvh - 62px); */
        width: calc(52dvh - 62px);
        max-width: 50vw;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: 
            ' . two . '
            ' four one five '
            ' . three . ';
    }

    .spread-ten-celtic-cross > section {
        /* width: calc(62dvh - 62px); */
        width: calc(52dvh - 62px);
        max-width: 50vw;
        grid-template-columns: 3fr 3fr 3fr 1fr 3fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-areas: 
            ' . three . . ten '
            ' five one six . nine '
            ' . four . . eight '
            ' . two . . seven ';
            margin-bottom: -10%;
            position: relative;
    }

/*-----------------------------------------------------> CELTIC CROSS SPREADS */
    .spread-thirteen-celtic-cross > section {
        grid-template-columns: 3fr 3fr 3fr 1fr 3fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas: 
            ' eleven twelve thirteen . ten '
            ' . three . . nine '
            ' five one six . eight '
            ' . four . . seven '
            ' . two . . . ';
        margin-bottom: -20%;
        width: calc(100dvh - 240px);
        position: relative;
    }
    @media only screen and (max-width: 640px) {
        .spread-thirteen-celtic-cross > section {
            margin-top: 0px;
        }
    }
    .spread-ten-celtic-cross > section > div:nth-of-type(2),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(2) {
        position: absolute;
        top: -200%;
        width: 100%;
        transform: rotate(90deg);
        z-index: 5;
    }


    @media (max-width: 749px) {
        .spread-cross > section,
        .spread-ten-celtic-cross > section {
            margin-top: 5vw;
            max-width: 90vw;
        }
    }


    /* .spread-ten-celtic-cross > section > div:nth-of-type(7),
    .spread-ten-celtic-cross > section > div:nth-of-type(8),
    .spread-ten-celtic-cross > section > div:nth-of-type(9),
    .spread-ten-celtic-cross > section > div:nth-of-type(10),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(7),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(8),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(9),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(10) {
        width: 90%;
    } */
    /* .spread-thirteen-celtic-cross > section > div:nth-of-type(11),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(12),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(13) {
        width: 80%!important;
        margin-top: 20%;
    } */

    /* .spread-ten-celtic-cross > section > div:nth-of-type(7) {
        transform: translateY(-48%);
    }
    .spread-ten-celtic-cross > section > div:nth-of-type(8) {
        transform: translateY(-32%);
    }
    .spread-ten-celtic-cross > section > div:nth-of-type(9) {
        transform: translateY(-16%);
    }
    .spread-ten-celtic-cross > section > div:nth-of-type(10) {
        transform: translateY(0%);
    } */

    /* .spread-thirteen-celtic-cross > section > div:nth-of-type(7) {
        transform: translateY(16%);
    }   
    .spread-thirteen-celtic-cross > section > div:nth-of-type(8) {
        transform: translateY(8%);
    }
    .spread-thirteen-celtic-cross > section > div:nth-of-type(9) {
        transform: translateY(0%);
    }
    .spread-thirteen-celtic-cross > section > div:nth-of-type(10) {
        transform: translateY(-8%);
    }

    .spread-thirteen-celtic-cross > section > div:nth-of-type(12) {
        transform: translateX(-24%);
    }
    .spread-thirteen-celtic-cross > section > div:nth-of-type(13) {
        transform: translateX(-48%);
    } */