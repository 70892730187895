@import url(https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
:root {
  /* ...color: var(--color-hex-... ); */
  --color-hex-wine: #490821;
  --color-hex-burgundy: #1f000c;
  --color-hex-black: #110C0E;
  --color-hex-darkgrey: #1F1E1F;
  --color-hex-grey: #555355;
  --color-hex-lightgrey: hsl(300, 1%, 50%);
  --color-hex-lightestgrey: hsl(300, 1%, 90%);
  --color-hex-white: #F6F5F4;
  --color-hex-lightgold: #E4C190;
  --color-hex-darkgold: #BF945D;

  /* ...color: hsla(var(--color-hsl-... ), 1)); */
  --color-hsl-wine: 337, 80%, 16%;
  --color-hsl-burgundy: 337, 100%, 6%;
  --color-hsl-black: 336, 17%, 6%;
  --color-hsl-darkgrey: 300, 2%, 12%;
  --color-hsl-grey: 300, 1%, 33%;
  --color-hsl-lightgrey: 300, 1%, 50%;
  --color-hsl-lightestgrey: 300, 1%, 90%;
  --color-hsl-white: 30, 10%, 96%;
  --color-hsl-lightgold: 35, 61%, 73%;
  --color-hsl-darkgold: 34, 43%, 56%;
/* Font family styles */
  --font-header: 'Julius Sans One', sans-serif;
  --font-fancy: 'Reenie Beanie', cursive;
  --font-type: 'Special Elite', courier;

/* Card specs */
  --card-height: 300px;
  --card-width: 172px;
  --card-border-radus: 16px;
}









body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  /* Darkmode = .dark-mode */
  color: #110C0E;
  color: var(--color-hex-black);
  background-color: #F6F5F4;
  background-color: var(--color-hex-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*********> global overrides */
* {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  line-height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}
a .cta {
  padding-top: 1rem;
  border-bottom: 1px solid #BF945D;
  border-bottom: 1px solid var(--color-hex-darkgold);
  display: inline-block;
  color: #BF945D;
  color: var(--color-hex-darkgold);
}
#nav-menu a {
  color: #110C0E;
  color: var(--color-hex-black);
}
button,
input,
select,
.btn {
  border: 1px solid var(--color-darkgrey);
  background: transparent;
  padding: 6px;
  font-size: 16px;
  color: var(--color-darkgrey);
  text-transform: uppercase;
}

button,
input[type=select],
input[type=option],
select,
.btn {
cursor: pointer;
}
@media only screen and (max-width: 639px){
  select {
    padding: 2px;
  }
}

input[type=text],
input[type=password],
input[type=number] {
  cursor: text;
}

button,
.btn {
  padding: 6px 36px;
  border-radius: 100px;
}

/* Disabled Button Style */
button:disabled,
button[disabled] {
  border-color: hsl(300, 1%, 50%)!important;
  border-color: var(--color-hex-lightgrey)!important;
  color: hsl(300, 1%, 50%)!important;
  color: var(--color-hex-lightgrey)!important;
}
button:disabled stop:first-of-type,
button[disabled] stop:first-of-type {
  stop-color: hsl(300, 1%, 50%)!important;
  stop-color: var(--color-hex-lightgrey)!important;
}
button:disabled stop:last-of-type,
button[disabled] stop:last-of-type {
  stop-color: #555355!important;
  stop-color: var(--color-hex-grey)!important;
}



a.btn {
  text-decoration: none;
}
.dark-style {
  background-color: var(--color-darkgrey);
  color: #fff;
}

@media only screen and (min-width: 640px){
  .mobile-only {
    display: none!important;
  }
  .deskop-only {
    display: block!important;
  }
}
@media only screen and (max-width: 639px){
  .mobile-only {
    display: block!important;
  }
  .desktop-only {
    display: none!important;
  }
}


h1, h2, h3, h4, a {
  font-family: 'Julius Sans One', sans-serif;
  font-family: var(--font-header);
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 21px;
}
h5 {
  font-size: 18px;
  font-weight: 500;
}
p, a {
  font-size: 18px;
}
p {
  line-height: 180%;
}
p + p {
  margin-top: 1rem;
}

h1.title {
  text-align: center;
  padding: 20px 0;
  color: var(--color-darkgrey);
}

.subtitle {
  margin-top: .5em;
  margin-bottom: 2rem;
}

a {
  color: var(--color-darkgrey);
  cursor: pointer;
}

.error {
  color: red;
}

.fancy {
  font-family: 'Reenie Beanie', cursive;
  font-family: var(--font-fancy);
  font-size: 160%;
}
.type {
  font-family: 'Special Elite', courier;
  font-family: var(--font-type);
}
@media only screen and (max-width: 639px){
  h1 {
    font-size: clamp(32px, 12vw, 48px);
  }
  h2 {
    font-size: clamp(24px, 9vw, 36px);
  }
  h3 {
    font-size: clamp(19px, 6vw, 24px);
  }
  h4 {
    font-size: clamp(16px, 5vw, 21px);
  }
  h5 {
    font-size: clamp(14px, 4vw, 18px);
  }
}




.grid {
  max-width: 900px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 450px;
}
.grid > section {
  display: flex;
  height: 100%;
}

/* active cursor pointer */
.live {
  cursor: pointer;
}




#index-deck {
  max-width: 1000px;
  margin: 0 auto;
}
.body-width {
  max-width: 900px;
  margin: 0 auto;
}


.signout-home {
  position: absolute;
  z-index: 3;
}

/* Dark Pallet */
.dark-pallet {
  background-color: #110C0E;
  background-color: var(--color-hex-black);
}
.dark-pallet * {
  color: #E4C190;
  color: var(--color-hex-lightgold);
}

.btn svg {
  --length: 24px;
  width: var(--length);
  height: var(--length);
}

.btn.icon {
  border: none!important;
  padding: 0!important;
}
.btn.icon + .btn.icon {
  margin-left: 14px!important;
}

button {
  cursor: pointer;
}
.cursor-help {
  cursor: help;
}

/* Hamburger Style */
#ham-menu {
  padding-right: 24px;
  overflow: hidden;

  -webkit-transform: translate(0%, 0%);

          transform: translate(0%, 0%);
  opacity: 1;
  transition: all 1s ease;
  background: #fff;
}

#ham-menu.hide-menu {
  -webkit-transform: translate(100%, -0%);
          transform: translate(100%, -0%);
  opacity: 0;
  transition: -webkit-transform .3 ease;
  transition: transform .3 ease;
  transition: transform .3 ease, -webkit-transform .3 ease;
}


@media (max-width: 749px) {  
  #ham-menu {
      background: #fff;
      height: 62px;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      top: 0;
  }
}

.modal-window {
  --width: 320px;
}
.modal-window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  min-height: 480px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Card Modal */
.modal {
    position: fixed;
    z-index: 20;
    width: 100vw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 639px) {
  .modal .hamburger-container {
    top: 16px;
  }
}
.modal-container {
  background: #F6F5F4;
  background: var(--color-hex-white);
  display: inline-block;
  box-shadow: 10px 9px 20px rgb(0 0 0 / 50%);
  width: calc(100vw - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.close-modal-box {
  position: fixed;
  z-index: 9;
  right: 36px;
  top: 30px;
  display: inline-block;
}
.close-modal-btn {
    font-size: 24px;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
button.close-modal-btn {
  padding: 0;
  margin: 0;
  line-height: 0;
  background: transparent;
  border: none;
}

@media (max-width: 749px) {
  .modal-container {
    margin: 0;
    padding: 12px;
    width: 100%;
    height: calc(100dvh - 0px);
  }
  .close-modal-box {
    right: 10px;
    top: 2px;
  }
}
#auth-container {
    transition: opacity 1s ease;
}
/* .modal-container {
    background-color: pink;
    min-height: 20px;
    width: var(--width);
    padding: 60px 0 20px;
    position: relative;
} */

#auth-container h1 {
    padding: 0 20px 10px;
}
#auth-container form {
    width: var(--width);
    padding-top: 10px;
}

#auth-container form input,
#auth-container form button  {
    font-size: 18px;
    display: block;
    margin: 10px auto;
    border-radius: 3px;
}
#auth-container form input  {
    height: 32px;
    width: 260px;
    font-size: 18px;
    border: 1px solid #DDD;
    padding: 0 10px;
}
#auth-container form button  {
    height: 36px;
    width: 282px;
    font-size: 18px;
    color: #fff;
    background: #000;
    border: 4px solid #000;
    padding: 0;
}
#auth-container form p {
    width: 100%;
    text-align: center;
    display: inline-block;
}

#auth-container section {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}
#auth-container .link-btn {
    display: inline-block;
    background: none;
    border: none;
    padding: 0 6px;
    text-decoration: underline;
    color: blue;
}
#auth-container .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}
.hamburger-container {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 11px;
    cursor: pointer;
}
/* .hamburger-container .hamburger-close {
  padding-top: 10px;
} */
.hamburger-container .hamburger-close > section {
  margin: 9px 0!important;
}
.hamburger-container .hamburger-close > section:first-of-type {
  -webkit-transform: rotate(45deg)!important;
          transform: rotate(45deg)!important;
}
.hamburger-container .hamburger-close > section:last-of-type {
  -webkit-transform: rotate(-45deg)!important;
          transform: rotate(-45deg)!important;
  margin-top: -12px!important;
}  

#hamburger-btn > section {
  margin: 6px 0;
  width: 30px;
  height: 3px;
  background-color: var(--color-hex-lightgold);
  transition: all .3s ease;
}


  
@media (max-width: 639px) {
  .hamburger-container {
    top: 0px;
  }
}

.draw-display #hamburger-btn.hamburger-close > section  {
  width: 20px;
  margin: 0px 0!important;
}

/* .draw-display #hamburger-btn.hamburger-close {
  padding-top: 10px;
  margin-top: -30px;
} */

.draw-display #hamburger-btn.hamburger-close > section:first-of-type {
  -webkit-transform: rotate(45deg)!important;
          transform: rotate(45deg)!important;
  margin-top: -3px!important;
}
.draw-display #hamburger-btn.hamburger-close > section:last-of-type {
  -webkit-transform: rotate(-45deg)!important;
          transform: rotate(-45deg)!important;
  margin-top: 9px!important;
}  
#nav-menu {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 8;
    transition: all 1s ease;
    background-color: #fff;
    height: 100%;
}



#nav-menu a,
 #nav-menu button {
    display: block;
    padding: 0;
    line-height: 100%;
    font-size: 18px;
    margin-bottom: 20px;
    border: none;
    background: transparent;
    text-decoration: none;
}

#nav-menu a {
    display: block;
    min-width: 320px;
    /* font-size: 18px; */
    margin-bottom: 20px;
}
#nav-menu a.active{
    text-decoration: underline;
}
#nav-menu > div {
    width: 100%;
    padding: 120px 60px;
}

/* Menu for Draw Page */
#nav-menu {
    padding-right: 24px;
    overflow: hidden;
  
    -webkit-transform: translate(0%, 0%);
  
            transform: translate(0%, 0%);
    opacity: 1;
    transition: all 1s ease;
    background: #fff;
    box-shadow: -4px 0px 50px rgba(0 0 0 / 12.5%);
  }
  
  #nav-menu.hide-menu {
    -webkit-transform: translate(100%, -0%);
            transform: translate(100%, -0%);
    opacity: 0;
    transition: -webkit-transform .3 ease;
    transition: transform .3 ease;
    transition: transform .3 ease, -webkit-transform .3 ease;
  }

.toggle-reverse-btn {
    opacity: .5;
  }
  @media (max-width: 749px) {  
    #nav-menu {
        background: #fff;
        height: 100dvh;
        width: 100%;
        max-width: 100vw;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        /* position: absolute; */
        top: 0;
    }
    #nav-menu a {
      margin-left: 16%; 
    }
  }
.spread-section { 
    display: grid; 
    max-width: 1200px;
    margin: 0 auto;
    grid-gap: 10px;
    gap: 10px;
}


.spread-section > div {
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 25%);
    overflow: hidden;
    transition: box-shadow 2s ease;
}
.spread-section > div:hover {
    cursor: pointer;
    box-shadow: 5px 5px 8px rgb(0 0 0 / 50%);
}
.spread-section > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Flip card orientation with toggle button */
.spread-section.toggle-orientation > div > img {
    -webkit-transform: rotate(0deg)!important;
            transform: rotate(0deg)!important;
}



/*-----------------------------------------------------> CARD PLACEMENT GRID AREA */
.spread-section > div:first-of-type { grid-area: one; }
.spread-section > div:nth-of-type(2) { grid-area: two; }
.spread-section > div:nth-of-type(3) { grid-area: three; }
.spread-section > div:nth-of-type(4) { grid-area: four; }
.spread-section > div:nth-of-type(5) { grid-area: five; }
.spread-section > div:nth-of-type(6) { grid-area: six; }
.spread-section > div:nth-of-type(7) { grid-area: seven; }
.spread-section > div:nth-of-type(8) { grid-area: eight; }
.spread-section > div:nth-of-type(9) { grid-area: nine; }
.spread-section > div:nth-of-type(10) { grid-area: ten; }
.spread-section > div:nth-of-type(11) { grid-area: eleven; }
.spread-section > div:nth-of-type(12) { grid-area: twelve; }
.spread-section > div:nth-of-type(13) { grid-area: thirteen; }

.spread-three-simple,
.spread-five-simple,
.spread-horseshoe {
    margin-top: 40px;
}

/*-----------------------------------------------------> SINGLE CARD */
    .random-single-card > section,
    .draw-single-card > section {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 100%;
        grid-template-areas: ' . one . ';
    }

/*-----------------------------------------------------> SIMPLE SPREADS */
    .spread-three-simple > section {
        max-width: 600px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 100%;
        grid-template-areas: ' one two three ';
    }
    .spread-five-simple > section {
        max-width: 600px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 100%;
        grid-template-areas: ' one two three four five ';
    }

/*-----------------------------------------------------> HORSESHOE SPREAD */
    .spread-seven-simple > section,
    .spread-horseshoe > section {
        max-width: 800px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr .5fr;
        grid-template-areas: 
            ' one . . . . . seven '
            ' . two three four five six . '
            ' . . . . . . . ';
    }
    .spread-horseshoe > section img { max-width: 100%!important; }
        
    .spread-horseshoe > section > div:nth-of-type(2),
    .spread-horseshoe > section > div:nth-of-type(6) {
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
    .spread-horseshoe > section > div:nth-of-type(4) {
        -webkit-transform: translateY(50%);
                transform: translateY(50%);
    }

/*-----------------------------------------------------> CROSS SPREAD */
    .spread-cross > section {
        /* max-width: 450px; */
        /* width: 33vw; */
        /* width: calc(62dvh - 62px); */
        width: calc(52dvh - 62px);
        max-width: 50vw;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: 
            ' . two . '
            ' four one five '
            ' . three . ';
    }

    .spread-ten-celtic-cross > section {
        /* width: calc(62dvh - 62px); */
        width: calc(52dvh - 62px);
        max-width: 50vw;
        grid-template-columns: 3fr 3fr 3fr 1fr 3fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-areas: 
            ' . three . . ten '
            ' five one six . nine '
            ' . four . . eight '
            ' . two . . seven ';
            margin-bottom: -10%;
            position: relative;
    }

/*-----------------------------------------------------> CELTIC CROSS SPREADS */
    .spread-thirteen-celtic-cross > section {
        grid-template-columns: 3fr 3fr 3fr 1fr 3fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas: 
            ' eleven twelve thirteen . ten '
            ' . three . . nine '
            ' five one six . eight '
            ' . four . . seven '
            ' . two . . . ';
        margin-bottom: -20%;
        width: calc(100dvh - 240px);
        position: relative;
    }
    @media only screen and (max-width: 640px) {
        .spread-thirteen-celtic-cross > section {
            margin-top: 0px;
        }
    }
    .spread-ten-celtic-cross > section > div:nth-of-type(2),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(2) {
        position: absolute;
        top: -200%;
        width: 100%;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        z-index: 5;
    }


    @media (max-width: 749px) {
        .spread-cross > section,
        .spread-ten-celtic-cross > section {
            margin-top: 5vw;
            max-width: 90vw;
        }
    }


    /* .spread-ten-celtic-cross > section > div:nth-of-type(7),
    .spread-ten-celtic-cross > section > div:nth-of-type(8),
    .spread-ten-celtic-cross > section > div:nth-of-type(9),
    .spread-ten-celtic-cross > section > div:nth-of-type(10),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(7),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(8),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(9),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(10) {
        width: 90%;
    } */
    /* .spread-thirteen-celtic-cross > section > div:nth-of-type(11),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(12),
    .spread-thirteen-celtic-cross > section > div:nth-of-type(13) {
        width: 80%!important;
        margin-top: 20%;
    } */

    /* .spread-ten-celtic-cross > section > div:nth-of-type(7) {
        transform: translateY(-48%);
    }
    .spread-ten-celtic-cross > section > div:nth-of-type(8) {
        transform: translateY(-32%);
    }
    .spread-ten-celtic-cross > section > div:nth-of-type(9) {
        transform: translateY(-16%);
    }
    .spread-ten-celtic-cross > section > div:nth-of-type(10) {
        transform: translateY(0%);
    } */

    /* .spread-thirteen-celtic-cross > section > div:nth-of-type(7) {
        transform: translateY(16%);
    }   
    .spread-thirteen-celtic-cross > section > div:nth-of-type(8) {
        transform: translateY(8%);
    }
    .spread-thirteen-celtic-cross > section > div:nth-of-type(9) {
        transform: translateY(0%);
    }
    .spread-thirteen-celtic-cross > section > div:nth-of-type(10) {
        transform: translateY(-8%);
    }

    .spread-thirteen-celtic-cross > section > div:nth-of-type(12) {
        transform: translateX(-24%);
    }
    .spread-thirteen-celtic-cross > section > div:nth-of-type(13) {
        transform: translateX(-48%);
    } */
