@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
:root {
  /* ...color: var(--color-hex-... ); */
  --color-hex-wine: #490821;
  --color-hex-burgundy: #1f000c;
  --color-hex-black: #110C0E;
  --color-hex-darkgrey: #1F1E1F;
  --color-hex-grey: #555355;
  --color-hex-lightgrey: hsl(300, 1%, 50%);
  --color-hex-lightestgrey: hsl(300, 1%, 90%);
  --color-hex-white: #F6F5F4;
  --color-hex-lightgold: #E4C190;
  --color-hex-darkgold: #BF945D;

  /* ...color: hsla(var(--color-hsl-... ), 1)); */
  --color-hsl-wine: 337, 80%, 16%;
  --color-hsl-burgundy: 337, 100%, 6%;
  --color-hsl-black: 336, 17%, 6%;
  --color-hsl-darkgrey: 300, 2%, 12%;
  --color-hsl-grey: 300, 1%, 33%;
  --color-hsl-lightgrey: 300, 1%, 50%;
  --color-hsl-lightestgrey: 300, 1%, 90%;
  --color-hsl-white: 30, 10%, 96%;
  --color-hsl-lightgold: 35, 61%, 73%;
  --color-hsl-darkgold: 34, 43%, 56%;
/* Font family styles */
  --font-header: 'Julius Sans One', sans-serif;
  --font-fancy: 'Reenie Beanie', cursive;
  --font-type: 'Special Elite', courier;

/* Card specs */
  --card-height: 300px;
  --card-width: 172px;
  --card-border-radus: 16px;
}









body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  /* Darkmode = .dark-mode */
  color: var(--color-hex-black);
  background-color: var(--color-hex-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*********> global overrides */
* {
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}
a .cta {
  padding-top: 1rem;
  border-bottom: 1px solid var(--color-hex-darkgold);
  display: inline-block;
  color: var(--color-hex-darkgold);
}
#nav-menu a {
  color: var(--color-hex-black);
}
button,
input,
select,
.btn {
  border: 1px solid var(--color-darkgrey);
  background: transparent;
  padding: 6px;
  font-size: 16px;
  color: var(--color-darkgrey);
  text-transform: uppercase;
}

button,
input[type=select],
input[type=option],
select,
.btn {
cursor: pointer;
}
@media only screen and (max-width: 639px){
  select {
    padding: 2px;
  }
}

input[type=text],
input[type=password],
input[type=number] {
  cursor: text;
}

button,
.btn {
  padding: 6px 36px;
  border-radius: 100px;
}

/* Disabled Button Style */
button:disabled,
button[disabled] {
  border-color: var(--color-hex-lightgrey)!important;
  color: var(--color-hex-lightgrey)!important;
}
button:disabled stop:first-of-type,
button[disabled] stop:first-of-type {
  stop-color: var(--color-hex-lightgrey)!important;
}
button:disabled stop:last-of-type,
button[disabled] stop:last-of-type {
  stop-color: var(--color-hex-grey)!important;
}



a.btn {
  text-decoration: none;
}
.dark-style {
  background-color: var(--color-darkgrey);
  color: #fff;
}

@media only screen and (min-width: 640px){
  .mobile-only {
    display: none!important;
  }
  .deskop-only {
    display: block!important;
  }
}
@media only screen and (max-width: 639px){
  .mobile-only {
    display: block!important;
  }
  .desktop-only {
    display: none!important;
  }
}


h1, h2, h3, h4, a {
  font-family: var(--font-header);
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 21px;
}
h5 {
  font-size: 18px;
  font-weight: 500;
}
p, a {
  font-size: 18px;
}
p {
  line-height: 180%;
}
p + p {
  margin-top: 1rem;
}

h1.title {
  text-align: center;
  padding: 20px 0;
  color: var(--color-darkgrey);
}

.subtitle {
  margin-top: .5em;
  margin-bottom: 2rem;
}

a {
  color: var(--color-darkgrey);
  cursor: pointer;
}

.error {
  color: red;
}

.fancy {
  font-family: var(--font-fancy);
  font-size: 160%;
}
.type {
  font-family: var(--font-type);
}
@media only screen and (max-width: 639px){
  h1 {
    font-size: clamp(32px, 12vw, 48px);
  }
  h2 {
    font-size: clamp(24px, 9vw, 36px);
  }
  h3 {
    font-size: clamp(19px, 6vw, 24px);
  }
  h4 {
    font-size: clamp(16px, 5vw, 21px);
  }
  h5 {
    font-size: clamp(14px, 4vw, 18px);
  }
}




.grid {
  max-width: 900px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 450px;
}
.grid > section {
  display: flex;
  height: 100%;
}

/* active cursor pointer */
.live {
  cursor: pointer;
}




#index-deck {
  max-width: 1000px;
  margin: 0 auto;
}
.body-width {
  max-width: 900px;
  margin: 0 auto;
}


.signout-home {
  position: absolute;
  z-index: 3;
}

/* Dark Pallet */
.dark-pallet {
  background-color: var(--color-hex-black);
}
.dark-pallet * {
  color: var(--color-hex-lightgold);
}

.btn svg {
  --length: 24px;
  width: var(--length);
  height: var(--length);
}

.btn.icon {
  border: none!important;
  padding: 0!important;
}
.btn.icon + .btn.icon {
  margin-left: 14px!important;
}

button {
  cursor: pointer;
}
.cursor-help {
  cursor: help;
}

/* Hamburger Style */
#ham-menu {
  padding-right: 24px;
  overflow: hidden;

  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 1s ease;
  background: #fff;
}

#ham-menu.hide-menu {
  transform: translate(100%, -0%);
  opacity: 0;
  transition: transform .3 ease;
}


@media (max-width: 749px) {  
  #ham-menu {
      background: #fff;
      height: 62px;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      top: 0;
  }
}

.modal-window {
  --width: 320px;
}
.modal-window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  min-height: 480px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Card Modal */
.modal {
    position: fixed;
    z-index: 20;
    width: 100vw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 639px) {
  .modal .hamburger-container {
    top: 16px;
  }
}
.modal-container {
  background: var(--color-hex-white);
  display: inline-block;
  box-shadow: 10px 9px 20px rgb(0 0 0 / 50%);
  width: calc(100vw - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.close-modal-box {
  position: fixed;
  z-index: 9;
  right: 36px;
  top: 30px;
  display: inline-block;
}
.close-modal-btn {
    font-size: 24px;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
button.close-modal-btn {
  padding: 0;
  margin: 0;
  line-height: 0;
  background: transparent;
  border: none;
}

@media (max-width: 749px) {
  .modal-container {
    margin: 0;
    padding: 12px;
    width: 100%;
    height: calc(100dvh - 0px);
  }
  .close-modal-box {
    right: 10px;
    top: 2px;
  }
}